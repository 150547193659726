import { BigNumber, ethers } from "ethers";
import mineAbi from "../abis/mine.abi";
// import mineAbi from "../abis/mineOld.abi";
// import mineOldAbi from "../abis/mineOld.abi";
import { decodeError } from "ethers-decode-error";
import toast from "react-hot-toast";


interface BaseProps {
    provider: any;
    walletAddress: string
}

export interface MineInfo {
    isSigned: boolean,
    lastGetBonusTime: BigNumber,
    lastMineTime: BigNumber,
    parentAddress: string,
    power: BigNumber,
    sonCount: BigNumber,
    totalPower: BigNumber,
    waitBonusInfo: {
        totalAmount: BigNumber,
        data: any[]
    }
}

export const multicallContractAddress = '0xF2de0ACA8f6bD30b773f458954034857dA9520cB'
export const mineContractAddress = '0xDa05a2a7D0A44Cd91384C9d9aC24ceF027b37088'
export const mineContractAddressOld = '0x07A40289805638c2eAD2f12475d0FDfa35c9Fe5A'

export async function getMineInfo(props: BaseProps) {
    let { provider, walletAddress } = props
    if (provider === undefined) {
        process.env.NODE_ENV === 'development' ? provider = new ethers.providers.JsonRpcProvider('http://localhost:8545') : provider = new ethers.providers.JsonRpcProvider('https://phic.io/rpc')
    }
    const signer = provider
    const contract = new ethers.Contract(mineContractAddress, mineAbi, signer)
    try {
        return await contract.getMineInfo(walletAddress, Math.floor(Date.now() / 1000))
    } catch (e) {
        const { error } = decodeError(e)
        return toast.error(error)
    }

}

interface BindParentProps extends BaseProps {
    parentAddress: string;
}

export async function bindParent(props: BindParentProps) {
    let { provider, parentAddress } = props
    if (provider === undefined) {
        process.env.NODE_ENV === 'development' ? provider = new ethers.providers.JsonRpcProvider('http://localhost:8545') : provider = new ethers.providers.JsonRpcProvider('https://phic.io/rpc')
    }
    const signer = provider.getSigner()
    const contract = new ethers.Contract(mineContractAddress, mineAbi, signer)
    try {
        return await contract.bindParent(parentAddress)
    } catch (e) {
        const { error } = decodeError(e)
        return toast.error(error)
    }

}

export async function receivePHB(props: BaseProps) {
    let { provider } = props
    if (provider === undefined) {
        process.env.NODE_ENV === 'development' ? provider = new ethers.providers.JsonRpcProvider('http://localhost:8545') : provider = new ethers.providers.JsonRpcProvider('https://phic.io/rpc')
    }
    const signer = provider.getSigner()
    const contract = new ethers.Contract(mineContractAddress, mineAbi, signer)
    try {
        return await contract.getBonus()
    } catch (e) {
        const { error } = decodeError(e)
        return toast.error(error)
    }

}

export async function doMine(props: BaseProps) {
    let { provider } = props
    if (provider === undefined) {
        process.env.NODE_ENV === 'development' ? provider = new ethers.providers.JsonRpcProvider('http://localhost:8545') : provider = new ethers.providers.JsonRpcProvider('https://phic.io/rpc')
    }
    const signer = provider.getSigner()
    const contract = new ethers.Contract(mineContractAddress, mineAbi, signer)
    try {
        return await contract.signToday({ value: ethers.utils.parseEther('0.05') })
    } catch (e: any) {
        //insufficient funds for
        if (typeof e === 'object' && e && Object.keys(e).includes('data') && e.data.message.includes('insufficient funds for')) {
            return toast.error('Insufficient funds')
        }
        const { error } = decodeError(e, mineAbi)
        console.log(error)
        return toast.error(error)
    }

}

export async function waitTx(tx: any) {
    try {
        return await tx.wait()
    } catch (e) {
        const { error } = decodeError(e)
        toast.error(error)
    }
}


interface ApproveContractProps {
    provider: any;
    tokenAddress: string;
    walletAddress: string;
    amount: BigNumber;
}

export async function ApproveContract(props: ApproveContractProps) {
    const signer = props.provider.getSigner()
    const contract = new ethers.Contract(props.tokenAddress, mineAbi, signer)
    const allowanceAmount = await contract.allowance(props.walletAddress, mineContractAddress)
    if (allowanceAmount.lt(props.amount)) {
        return await contract.approve(mineAbi, ethers.constants.MaxUint256)
    }
    return true
}

export function getUtcAtZero() {
    // 获取当前日期和时间的UTC 0点时间戳
    const now = new Date();
    const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0);

    // console.log(utcTimestamp);
    return utcTimestamp / 1000
}

export function formatTimestamp(timestamp: BigNumber) {
    if (timestamp === undefined) {
        return '-'
    }
    const date = new Date(timestamp.toNumber() * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function hiddenAddress(str: string, frontLen: number, endLen: number) {
    let star = "";
    for (let i = 0; i < 4; i++) {
        star += "*";
    }
    return (str.substring(0, frontLen) + star + str.substring(str.length - endLen));
}

interface GetSonListProps extends BaseProps {
    page: number;
    pageSize: number;
}

export async function getSonList(props: GetSonListProps) {
    let { provider, walletAddress, page, pageSize } = props
    if (provider === undefined) {
        process.env.NODE_ENV === 'development' ? provider = new ethers.providers.JsonRpcProvider('http://localhost:8545') : provider = new ethers.providers.JsonRpcProvider('https://phic.io/rpc')
    }
    // provider = new ethers.providers.JsonRpcProvider('http://localhost:18545')
    const signer = provider
    const contract = new ethers.Contract(mineContractAddress, mineAbi, signer)
    try {
        return await contract.getSonAddressList(Math.floor(Date.now() / 1000), page, pageSize, walletAddress)
    } catch (e) {
        const { error } = decodeError(e)
        return toast.error(error)
    }

}

export const CHAIN_ID = 10618