import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './stars.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from "react-hot-toast";
import { Providers } from "./providers";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Providers>
            <App/>
        </Providers>
        <Toaster containerStyle={{ top: '50%' }} toastOptions={{ error: { duration: 3000 } }}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
