import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './App.css';
import close from './images/close.png'
import toast from "react-hot-toast";
// import { MetaMask, NoMetaMaskError } from "@web3-react/metamask";
import { useWeb3React } from "@web3-react/core";
import {
    bindParent,
    CHAIN_ID,
    getMineInfo,
    getSonList,
    getUtcAtZero,
    hiddenAddress,
    MineInfo,
    receivePHB,
    waitTx
} from "./ethers_util";
import { BigNumber, ethers } from "ethers";
import { Button, Footer, Input, Mask } from "antd-mobile";


Modal.setAppElement('#root');

function App() {
    const { chainId, accounts, connector, provider } = useWeb3React()
    const [visible, setVisible] = useState(false)
    const [parentAddress, setParentAddress] = useState('')
    const [mineInfo, setMineInfo] = React.useState<MineInfo>({
        isSigned: false,
        lastGetBonusTime: BigNumber.from(0),
        lastMineTime: BigNumber.from(0),
        parentAddress: '',
        power: BigNumber.from(0),
        sonCount: BigNumber.from(0),
        totalPower: BigNumber.from(0),
        waitBonusInfo: { totalAmount: BigNumber.from(0), data: [] }
    })
    const [isShow, setIsShow] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)
    const [sonList, setSonList] = React.useState([])
    useEffect(() => {
        if (chainId && chainId !== CHAIN_ID) {
            toast.error('Please switch to the PhiChain network')
            handleToggleConnect()
        } else if (chainId && accounts && provider) {
            // console.log(11111111111111111111111)
            getMI()
        }
    }, [chainId, accounts])

    const getMI = async () => {
        if (!accounts || accounts.length === 0 || !provider) {
            toast.error('Please connect wallet')
            return
        }
        const la = toast.loading('Loading...')
        const con = await getMineInfo({ provider: provider, walletAddress: accounts[0] })
        // console.log(con)
        toast.dismiss(la)
        if (typeof con !== 'string') {
            setMineInfo(con)
        }
    }
    const bindP = async () => {
        if (!accounts || accounts.length === 0 || !provider) {
            toast.error('Please connect wallet')
            return
        }
        if (parentAddress === '' || !ethers.utils.isAddress(parentAddress)) {
            toast.error('Please input correct address')
            return
        }
        // Dialog.show({
        //     closeOnMaskClick: true,
        //     bodyStyle:{padding:0},
        //     content: <div className="bind-modal"></div>
        // })
        // setIsShow(true)
        const la = toast.loading('Waiting for wallet signature...')

        const con = await bindParent({
            provider: provider,
            walletAddress: accounts[0],
            parentAddress: parentAddress
        })
        toast.dismiss(la)
        if (typeof con !== 'string') {
            const la2 = toast.loading('Waiting for confirmation...')
            await waitTx(con)
            setVisible(false)
            toast.dismiss(la2)
            toast.success('Bind success')
            await getMI()
        }

    }
    const receiveP = async () => {
        if (!accounts || accounts.length === 0 || !provider) {
            toast.error('Please connect wallet')
            return
        }
        if (mineInfo.waitBonusInfo.totalAmount.eq(0)) {
            toast.error('No PHB to be claimed')
            return
        }
        const la = toast.loading('Waiting for wallet signature...')
        const con = await receivePHB({ provider: provider, walletAddress: accounts[0] })
        toast.dismiss(la)
        if (typeof con !== 'string') {
            const la2 = toast.loading('Waiting for confirmation...')
            await waitTx(con)
            toast.dismiss(la2)
            toast.success('Receive success')
            await getMI()
        }
    }


    const doM = async () => {
        toast.error('Mining is not available')
        return
    }
    const getSon = async (page: number, pageSize: number) => {
        const lo = toast.loading('Loading...')
        if (!accounts || accounts.length === 0 || !provider) {
            toast.dismiss(lo)
            toast.error('Please connect wallet')
            return
        }
        const con = await getSonList({ provider: provider, walletAddress: accounts[0], page: page, pageSize: pageSize })
        toast.dismiss(lo)
        console.log(con)
        if (typeof con !== 'string') {
            setSonList(con.pageData)
        }
    }
    useEffect(() => {
        if (isShow) {
            getSon(page, pageSize)
        }
    }, [isShow, page, pageSize]);
    const handleToggleConnect = () => {
        if (!provider) {
            return
        }
        // // return
        Promise.resolve(connector.activate(CHAIN_ID))
            .catch((e) => {
                // connector.resetState()
                toast.error(e.message)
            }).then(() => {
        })

    }
    const prePage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    const nextPage = () => {
        if (sonList.length === pageSize) {
            setPage(page + 1)
        }
    }
    const closeSonList = () => {
        setIsShow(false)
        setSonList([])
        setPage(1)
    }
    const getSonState = (lastMineTime: number) => {
        const utcTimestamp = getUtcAtZero()
        // console.log(lastMineTime)
        // console.log(utcTimestamp)
        return lastMineTime > utcTimestamp
    }
    useEffect(() => {
        handleToggleConnect()
    }, []);

    return (
        <>

            <div className={'header'}>
                <div className="topbar">
                    <div className="body inner">
                        <div className="tag">
                            PHIBASE
                        </div>
                        <div className="promoline">
                            PhiBase NET MPC power: <strong>{mineInfo.totalPower.toString()}ph</strong>
                        </div>

                    </div>
                </div>
            </div>
            <div className={'page'}>
                <div className="service-header win">
                    <div className="starfield" id='starfield-1'></div>
                    <div className="starfield" id='starfield-2'></div>
                    <div className="starfield" id='starfield-3'></div>

                    <div className="circle-animation"></div>

                    <div className="body">
                        <div className='connect-wallet' onClick={handleToggleConnect}>
                            {accounts ? hiddenAddress(accounts[0], 4, 4) : 'Connect Wallet'}
                        </div>
                        <div className="title">
                            PhiBase Framework already launched
                        </div>
                        <div className="subtitle">
                            Using PhiBase and Phi Exchange, provides the best performance in block network compatible
                            chain solutions!
                        </div>
                    </div>


                    <div className="wave-animation darkblue">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="service-big-icon">
                        {/*<img src={bw} alt=""/>*/}
                    </div>
                    <div className="bottom">

                        <span>Current power: {mineInfo.power.toString()}</span>
                        <span onClick={() => setIsShow(true)}>Private Pool miner: <span
                            style={{
                                textDecorationLine: 'underline',
                                textDecorationColor: '#E16500FF'
                            }}>{mineInfo.sonCount.toString()}</span> addresses</span>
                        <span>PHB to be claimed: {Number(ethers.utils.formatEther(mineInfo.waitBonusInfo.totalAmount)).toFixed(2)}
                            <span
                                style={{
                                    color: 'white',
                                    textDecorationLine: 'underline',
                                    textDecorationColor: '#E16500FF',
                                    marginLeft: '5px'
                                }}
                                onClick={receiveP}>Claim now</span></span>
                        <div className='mine-btn' onClick={doM}>Mine</div>
                    </div>
                </div>
            </div>
            <Mask visible={visible} onMaskClick={() => setVisible(false)}>
                <div className='bind-modal-par'>

                    <div className="bind-modal">
                        <Input placeholder={'Input parent address'} value={parentAddress} onChange={setParentAddress}
                               className='address-input'/>
                        <Button className='bind-btn' onClick={bindP}>JOIN</Button>
                    </div>
                </div>
            </Mask>
            <Mask visible={isShow} onMaskClick={closeSonList} disableBodyScroll={false} className='list-modal'
                  destroyOnClose={true}>
                <div className="my-list">
                    <div className='list-header'>
                        <div className='list-title'>PhiBase Details</div>
                        <div className='list-close' onClick={closeSonList}>
                            <img src={close} alt={''} width={'30px'}/>
                        </div>
                    </div>
                    <div className='list-column'>
                        <div className='list-column-item'>Serial</div>
                        <div className='list-column-item'>Address</div>
                        <div className='list-column-item'>State</div>
                    </div>
                    <div className='list-body'>
                        {sonList.map((item: any, index: number) => {
                            return (
                                <div className='list-body-item' key={index}
                                     style={{ backgroundColor: index % 2 === 0 ? 'rgb(113,205,249,.1)' : 'transparent' }}>
                                    <div className='list-body-item-column'>{index + 1}</div>
                                    <div className='list-body-item-column'>{hiddenAddress(item.add, 4, 4)}</div>
                                    <div
                                        className='list-body-item-column'
                                        style={{ color: getSonState(item.lastMineTime.toNumber()) ? 'green' : 'red' }}>{getSonState(item.lastMineTime.toNumber()) ? 'On' : 'Off'}</div>
                                </div>
                            )
                        })}
                        {sonList.length > 0 ? <></> :
                            <Footer label='No more data' style={{ backgroundColor: 'transparent' }}></Footer>}
                    </div>
                    <div className='list-foot'>
                        <div className='list-foot-item' onClick={prePage}>
                            {'<'}
                        </div>
                        <div className='list-foot-item' onClick={nextPage}>
                            {'>'}
                        </div>
                    </div>

                </div>
            </Mask>

            {/*<Modal isOpen={true} shouldCloseOnOverlayClick={true} onRequestClose={()=>setIsShow(false)} style={modalStyle} parentSelector={() => document.body} >*/}
            {/*    <div className="bind-modal">*/}
            {/*       <Input placeholder={'parent address'}/>*/}
            {/*    </div>*/}
            {/*</Modal>*/}

        </>
    );
}

export default App;
