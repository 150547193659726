import { MetaMask } from "@web3-react/metamask";
import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import { useEffect } from "react";
import { Connector } from "@web3-react/types";

const connectors: [MetaMask, Web3ReactHooks][] = [
    [metaMask, metaMaskHooks],
]
const connect = async (connector: Connector) => {
    try {
        if (connector.connectEagerly) {
            await connector.connectEagerly();
        } else {
            await connector.activate();
        }
    } catch (error) {
        console.debug(`web3-react eager connection error: ${error}`);
    }
}

export function Providers({ children }: { children: any }) {
    useEffect(() => {
        connect(metaMask)
    }, []);
    return (
        <Web3ReactProvider connectors={connectors}>
            {children}
        </Web3ReactProvider>
    );
}